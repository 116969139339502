@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Muller';
  src: url('./fonts/muller.ttf') format('truetype'); /* путь зависит от вашего расположения файла */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1A2C38;
}

/* Стиль для затемнённого фона */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Полупрозрачный чёрный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Анимированный лоадер */
.loader-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid transparent; /* Прозрачная граница */
  border-top: 8px solid #ffffff; /* Белая дуга */
  border-right: 8px solid #ffffff; /* Дополнительная часть дуги */
  border-radius: 50%; /* Круглая форма */
  animation: spin 1s linear infinite; /* Бесконечная анимация */
}

/* Анимация вращения */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}